<!-- 故障反馈 -->
<template>
  <div class="fault">
    <div class="fault-form">
      <div class="fault-title">
        <p><i>*</i>故障类型</p>
      </div>

      <ul class="type-list">
        <li
          :class="type == item ? 'active' : ''"
          v-for="item in type_list"
          :key="item"
          @click="change_type(item)"
        >
          {{ item }}
        </li>
      </ul>

      <div class="fault-title">
        <p><i>*</i>故障设备</p>
      </div>
      <input class="fault-device" readonly type="text" v-model="device" />
      <div class="fault-title">
        <p>故障图片</p>
        <span>{{ file_list.length }}/4</span>
      </div>

      <van-uploader
        class="fault-uploader"
        v-model="file_list"
        :after-read="afterRead"
        :max-count="4"
        :max-size="5000 * 1024"
        :multiple="true"
        @oversize="onOversize"
        :before-delete="delete_img"
      />

      <div class="fault-title"><p>故障详情</p></div>
      <div class="fault-detail">
        <textarea
          placeholder="请描述故障的详情"
          maxlength="100"
          v-model="detail"
        ></textarea>
        <p class="num">{{ detail.length > 100 ? 100 : detail.length }}/100</p>
      </div>
    </div>

    <div class="fault-btn">
      <button @click="submit">提交</button>
    </div>
  </div>
</template>

<script>
import { getData, postData } from "@/api/user";
import { _debounce } from "@/libs/util";

export default {
  data() {
    return {
      charger_id: 0,
      port_id: 0,
      page: "",
      type: "离线",
      type_list: [
        "离线",
        "不通电",
        "二维码错误",
        "插座故障",
        "充一会就断电",
        "其他",
      ],
      file_list: [],
      img_list: [],
      num: 0,
      device: "",
      detail: "",
    };
  },
  beforeCreate() {
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#fff");
  },
  created() {
    this.charger_id = this.$route.query.charger_id;
    this.port_id = this.$route.query.port_id;
    this.page = this.$route.query.type;
    this.device =
      this.page == "power"
        ? `编号${this.charger_id}   端口${this.port_id}号`
        : `编号${this.charger_id}`;

    getData({
      url: "api/charging/fault/index",
      data: {},
    }).then((res) => {
      console.log(res);
      var data = res.data.data;
      if (res.data.code === 200) {
        this.type_list = data.list;
      } else if (res.data.code === 422) {
        this.$toast(res.data.data.msg);
      }
    });
  },
  methods: {
    change_type(item) {
      this.type = item;
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      if (!file.length) {
        let formDate = new FormData();
        formDate.append("image", file.file);
        this.$toast.loading({
          message: "图片上传中...",
          forbidClick: true,
          duration: 0,
        });

        postData({
          url: `api/charging/charger/upload`,
          data: formDate,
        }).then((res) => {
          let data = res.data;
          if (res.data.code === 200) {
            let arr = this.img_list;
            arr.push(data.data.pathUrl);
            this.img_list = arr;
            console.log(arr, this.img_list);
            this.$toast.success("上传成功");
          } else if (data.code === 422) {
            this.$toast(data.data.msg);
          }
        });
      } else {
        let arr = file;
        let num = 0;

        let arr_len = arr.length;
        arr.forEach((e) => {
          this.$toast.loading({
            message: "图片上传中...",
            forbidClick: true,
            duration: 0,
          });
          let formDate = new FormData();
          formDate.append("image", e.file);

          postData({
            url: `api/charging/charger/upload`,
            data: formDate,
          }).then((res) => {
            let data = res.data;
            if (res.data.code === 200) {
              let arr = this.img_list;
              arr.push(data.data.pathUrl);
              this.img_list = arr;

              num++;
              if (num == arr_len) {
                this.$toast.success("上传成功");
              }
            } else if (data.code === 422) {
              this.$toast(data.data.msg);
            }
          });
        });
      }
    },
    delete_img(file, name) {
      console.log(file, name);
      console.log(this.img_list);
      this.img_list.splice(name.index, 1);
      return true;
    },
    onOversize(file) {
      console.log(file);
      this.$toast("文件大小不能超过 5MB");
    },
    submit: _debounce(function () {
      postData({
        url: `api/charging/charger/fault`,
        data: {
          fault_type: this.type,
          charger_id: this.charger_id,
          port_id: this.port_id,
          fault_des: this.detail,
          image: this.img_list,
        },
      }).then((res) => {
        let data = res.data;
        if (data.code === 200) {
          this.$toast(data.data.msg);
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        } else if (data.code === 422) {
          this.$toast(data.data.msg);
        }
      });
    }),
  },
};
</script>

<style lang="less">
.fault {
  .fault-form {
    padding: 32px 12px 80px;
  }
  .fault-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 16px;
    font-size: 15px;
    line-height: 21px;
    color: #333333;
    i {
      color: #eb0202;
    }
    span {
      font-size: 12px;
      color: #999;
    }
  }
  .type-list {
    margin-bottom: 16px;
    li {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      margin: 0 12px 16px 0;
      background: #f2f2f2;
      border-radius: 4px;
      font-size: 14px;
      color: #333;
      padding: 0 22px;
      border: 1px solid #f2f2f2;
    }
    li.active {
      background: #fff;
      border: 1px solid #01bc84;
      color: #01bc84;
    }
  }
  .fault-device {
    width: 100%;
    height: 40px;
    background: #fff;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: #333;
    padding: 0 10px;
    margin-bottom: 32px;
    -webkit-appearance: none;
  }

  .fault-btn {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 12px;
    background: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    button {
      height: 44px;
      width: 100%;
      border: none;
      outline: none;
      line-height: 44px;
      background: #3dc48d;
      border-radius: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .fault-uploader {
    margin-bottom: 32px;
  }
  .fault-detail {
    padding: 12px;
    margin-bottom: 12px;
    width: 100%;
    background: #fff;
    border: 1px solid #cbcbcb;
    textarea {
      height: 150px;
      width: 100%;
      border: 0;
      resize: none;
      font-size: 14px;
      line-height: 20px;
      color: #333;
    }
    .num {
      text-align: right;
      font-size: 12px;
      line-height: 17px;
      color: #999;
    }
  }
  .van-uploader__preview {
    margin: 0 7px 7px 0;
  }
}
</style>